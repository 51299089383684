import { createContext, useContext, useState } from 'react';
import { SESSION_STORAGE_KEY } from '../../views/boffice/utils/Constants/routesContants';
const AuthContext = createContext();

const setAuthToken = (token) =>
  sessionStorage.setItem(SESSION_STORAGE_KEY, token);
const removeAuthToken = () => sessionStorage.removeItem(SESSION_STORAGE_KEY);

const AuthProviderBoffice = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(() => {
    return sessionStorage.getItem(SESSION_STORAGE_KEY) ? true : false;
  });

  const loginBoffice = (token) => {
    setAuthToken(token);
    setAuthenticated(true);
  };

  const logoutBoffice = () => {
    removeAuthToken();
    sessionStorage.removeItem('userPermissions');
    setAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, loginBoffice, logoutBoffice }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthBoffice = () => {
  const context = useContext(AuthContext);
  return context;
};

export { AuthProviderBoffice, useAuthBoffice };
